import "../styles/404.scss"

export default function deny() {
  return (
    <div className="special">
      <img className="icon" src="../images/403.svg" alt="" />
      <div className="word">
        <h3>Forbidden</h3>
        <p>Access is denied due to your current untrusted network,please switch to the secure network and try again.<br />
          If you have any questions,please contact <a className="mail" href="mailto:API_Service@bmw-brilliance.cn">API_Service@bmw-brilliance.cn</a> for further help.</p>
        <a href="/">Back Home</a>
      </div>
    </div>
  )
}